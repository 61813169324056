.standardCol {
  padding: 1rem;
}
.pricingCol {
  padding-right: 0.3rem;
}
.pricingRow {
  padding: 0px !important;
  margin: 0px !important;
}
.ant-form-item {
  margin-bottom: 0px !important;
}
h7 {
  font-weight: bold;
}
h6 {
  font-size: 14px;
  padding-top: 10px;
}
h5 {
  font-size: 18px;
  padding-top: 10px;
}

.ant-form-text {
  padding-top: 10px !important;
}

.deleteButton {
  font-size: 5px;
}
.noHeight {
  height: 0px;
}

.bigCheck input[type="checkbox"] {
  /* width: 200px;
  height: 100px; */
}

.anticon {
  vertical-align: 0 !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: rgb(41, 54, 66) !important;
}
.pricingCol label {
  display: block;
}

.ant-checkbox-wrapper {
  text-align: left;
}

.pricingCol .ant-checkbox-wrapper > span {
  padding: 0px !important;
}

.ant-col-2 {
  max-width: unset !important;
}
